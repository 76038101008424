<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Backups</h2>

          <p>
            Configure daily backups to make sure you never lose your data.
            Browse and restore snapshots, or take additional backups on demand.
            You are allowed up to 10 databases per website.
            <strong>Need a hand?</strong> Click the button below and one of our
            experts will help you configure your website backups.
          </p>

          <button class="button is-dark is-outlined" @click="getBackupHelp">
            <span>Get help</span>
            <b-icon icon="question-circle" size="is-small" />
          </button>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <div class="column is-12">
            <collapse title="File backups">
              <file-backups-card :site-id="siteId" />
            </collapse>
          </div>

          <div class="column is-12">
            <collapse title="Database backups">
              <db-backups-card :site-id="siteId" />
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc } from "@firebase/firestore";
export default {
  name: "SiteBackups",
  components: {
    "file-backups-card": () => import("@shared/backups/_fileBackupsCard"),
    "db-backups-card": () => import("@shared/backups/_dbBackupsCard")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    }
  },
  methods: {
    getBackupHelp() {
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId,
        taskRef: doc(this.$firestore, `taskTypes/set-up-backups`)
      });
    }
  }
};
</script>
